<template>
	<v-container fluid>
		<div v-if="isLoading">
			<v-col cols="4">
				<v-skeleton-loader
					class="mx-auto"
					width="100%"
					type="card"
				></v-skeleton-loader>
			</v-col>
		</div>
		<v-row v-else class="d-flex">
			<v-col
				cols="4"
				v-for="(email, index) in emails"
				:key="index"
				class="d-flex"
			>
				<v-card class="pb-5 mb-0 pa-4" width="100%">
					<v-row class="d-flex">
						<!-- Couldn't find way to align v-menu to right, so temporarily did this one. -->
						<v-col cols="1" class="pa-0"></v-col>
						<v-col cols="10">
							<p class="text-center mt-6 add pb-8 border">
								{{ email }}
							</p>
						</v-col>
						<v-col cols="1" class="pa-0">
							<v-menu class="d-flex justify-start">
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item
										v-for="(item, i) in items"
										:key="i"
										@click="
											menuActionClick(item.action, email)
										"
									>
										<v-list-item-title>{{
											item.title
										}}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
						<v-col cols="12 justify-space-between" class="pa-4">
							<v-btn
								block
								large
								outlined
								color="primary"
								@click="accountSelected(email)"
							>
								Select
							</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
			<v-col cols="4" class="d-flex">
				<v-card class="add pb-5 mb-0 pa-4" width="100%">
					<div class="d-flex flex-column">
						<div class="justify-content-center d-flex flex-column">
							<p class="text-center mt-10 text-h5 add">
								Add New Account
							</p>
							<v-btn
								large
								rounded
								color="primary"
								@click="login()"
								class="align-self-center mt-10 mb-4"
							>
								<v-icon left>mdi-plus</v-icon>
								Add
							</v-btn>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			isLoading: false,
			emails: [],
			items: [{ title: 'Logout', action: 'logout' }],
		}
	},
	computed: {
		authUrl: {
			get() {
				return this.$store.getters['gmb/getAuthUrl']
			},
		},
	},
	created() {
		this.getAccounts()
	},
	methods: {
		menuActionClick(action, email) {
			if (action === 'logout') {
				this.accountLogOut(email)
			}
		},
		async accountSelected(selectedEmail) {
			this.$cookies.set('gmb-account-email', selectedEmail)
			this.$router.push({
				name: 'gmb.orgs',
			})
		},
		login() {
			window.location.href = this.authUrl
		},
		async accountLogOut(email) {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'post',
				url: `/${projectId}/gmb/logout`,
				data: { email },
			})
				.then((response) => {
					this.$snackbar.notify({
						message: response.data?.message,
						color: 'yellow',
					})
					this.$cookies.remove('gmb-account-email')
					this.getAccounts()
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
		getAccounts() {
			this.isLoading = true
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/gmb/accounts`,
			})
				.then((res) => {
					this.$nextTick(function () {
						this.emails = res.data.data.emails
					})
					this.isLoading = false
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
					this.isLoading = false
				})
		},
	},
}
</script>

<style scoped>
.accounts {
	background-color: #f6f7f9 !important;
}
.add {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1.2rem;
}
.add-new {
	background-color: var(--blue) !important;
	color: white;
	align-self: end;
}
.border {
	border-bottom: 1px solid #eee;
}
</style>
